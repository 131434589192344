var isIE=(navigator.appVersion.indexOf("MSIE")!=-1)?!0:!1;var isWin=(navigator.appVersion.toLowerCase().indexOf("win")!=-1)?!0:!1;var isOpera=(navigator.userAgent.indexOf("Opera")!=-1)?!0:!1;var jsReady=!1;var swfReady=!1;function isReady()
{return jsReady}
var flash_callback;function setSWFIsReady()
{swfReady=!0;if((flash_callback!=null)&&(flash_callback!=""))
{eval(flash_callback)}}
function InitializeFlash(callback)
{if((callback!=null)&&(callback!=""))
{flash_callback=callback}
jsReady=!0}
function storeData(objectLabel,formElem)
{if(swfReady)
{var toStore=formElem.value;formElem.value="";getSWF("LocalStore").setStoredData(objectLabel,toStore)}}
function retrieveData(objectLabel,formElem)
{if(swfReady)
{var storedData=getSWF("LocalStore").getStoredData(objectLabel);formElem.value=storedData}}
function getSWF(movieName)
{if(navigator.appName.indexOf("Microsoft")!=-1)
{return window[movieName]}
else{var val=document[movieName];if(val.length)
{var i=0;for(i=0;i<val.length;++i)
{if(val[i].nodeName=="EMBED"||val[i].nodeName=="embed")
{return val[i]}}}
return val}}
function ControlVersion()
{var version;var axo;var e;try{axo=new ActiveXObject("ShockwaveFlash.ShockwaveFlash.7");version=axo.GetVariable("$version")}catch(e){}
if(!version)
{try{axo=new ActiveXObject("ShockwaveFlash.ShockwaveFlash.6");version="WIN 6,0,21,0";axo.AllowScriptAccess="always";version=axo.GetVariable("$version")}catch(e){}}
if(!version)
{try{axo=new ActiveXObject("ShockwaveFlash.ShockwaveFlash.3");version=axo.GetVariable("$version")}catch(e){}}
if(!version)
{try{axo=new ActiveXObject("ShockwaveFlash.ShockwaveFlash.3");version="WIN 3,0,18,0"}catch(e){}}
if(!version)
{try{axo=new ActiveXObject("ShockwaveFlash.ShockwaveFlash");version="WIN 2,0,0,11"}catch(e){version=-1}}
return version}
function GetSwfVer()
{var flashVer=-1;if(navigator.plugins!=null&&navigator.plugins.length>0){if(navigator.plugins["Shockwave Flash 2.0"]||navigator.plugins["Shockwave Flash"]){var swVer2=navigator.plugins["Shockwave Flash 2.0"]?" 2.0":"";var flashDescription=navigator.plugins["Shockwave Flash"+swVer2].description;var descArray=flashDescription.split(" ");var tempArrayMajor=descArray[2].split(".");var versionMajor=tempArrayMajor[0];var versionMinor=tempArrayMajor[1];if(descArray[3]!=""){tempArrayMinor=descArray[3].split("r")}else{tempArrayMinor=descArray[4].split("r")}
var versionRevision=tempArrayMinor[1]>0?tempArrayMinor[1]:0;var flashVer=versionMajor+"."+versionMinor+"."+versionRevision}}
else if(navigator.userAgent.toLowerCase().indexOf("webtv/2.6")!=-1)flashVer=4;else if(navigator.userAgent.toLowerCase().indexOf("webtv/2.5")!=-1)flashVer=3;else if(navigator.userAgent.toLowerCase().indexOf("webtv")!=-1)flashVer=2;else if(isIE&&isWin&&!isOpera){flashVer=ControlVersion()}
return flashVer}
function DetectFlashVer(reqMajorVer,reqMinorVer,reqRevision)
{versionStr=GetSwfVer();if(versionStr==-1){return!1}else if(versionStr!=0){if(isIE&&isWin&&!isOpera){tempArray=versionStr.split(" ");tempString=tempArray[1];versionArray=tempString.split(",")}else{versionArray=versionStr.split(".")}
var versionMajor=versionArray[0];var versionMinor=versionArray[1];var versionRevision=versionArray[2];if(versionMajor>parseFloat(reqMajorVer)){return!0}else if(versionMajor==parseFloat(reqMajorVer)){if(versionMinor>parseFloat(reqMinorVer))
return!0;else if(versionMinor==parseFloat(reqMinorVer)){if(versionRevision>=parseFloat(reqRevision))
return!0}}
return!1}}